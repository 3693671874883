@import '~normalize.css/normalize.css';
@import '~@sk/components/lib/styles/global';
@import '~@sk/components/lib/Layout/style';
@import '~antd/dist/antd.css';
@import 'cursor';

:root {
  /* theme color */
  --theme-color: #249afa;

  /* common color */
  --white-color: #fff;
  --white-f0-color: #f0f0f0;
  --black-color: #000;
  --black-0f-color: #0f0b00;
  --black-1b-color: #1b1b1b;
  --gray-a1-color: #8f94a1;
  --gray-c9-color: #c9c9c9;
  --gray-cf-color: #cfcfcf;
  --gray-d-color: #ddd;
  --gray-d3-color: #d3d3d3;
  --gray-d7-color: #d7d7d7;
  --gray-d8-color: #d8d8d8;
  --gray-d9-color: #d9d9d9;
  --gray-e0-color: #e0e0e0;
  --gray-e1-color: #e1e1e1;
  --gray-e3-color: #e3e3e3;
  --gray-f5-color: #f5f5f5;
  --gray-g-color: #5c6989;
  --gray-51-color: #525151;
  --gray-52-color: #525252;
  --gray-5b-color: #5b5b5b;
  --gray-5e-color: #40455e;
  --gray-65-color: #656565;
  --gray-69-color: #746969;
  --gray-74-color: #747474;
  --gray-96-color: #969696;
  --gray-97-color: #979797;
  --gray-3a-color: #3a3a3a;
  --gray-h-color: #1c2230;
  --gray-3-color: #333;
  --gray-6-color: #666;
  --gray-9-color: #999;
  --gray-f6-color: #faf6f6;
  --gray-ba-color: #bababa;
  --gray-b7-color: #b7b7b7;
  --gray-84-color: #848484;
  --blue-a-color: #111629;
  --blue-d-color: #364051;
  --blue-e-color: #2389dd;
  --blue-f-color: #2ca0c9;
  --blue-g-color: #226194;
  --blue-h-color: #4aaeff;
  --blue-j-color: #183c5a;
  --blue-k-color: #1c2442;
  --blue-2e-color: #141c2e;
  --blue-l-color: #3eb2d8;
  --blue-m-color: #2a3351;
  --blue-5b-color: #2a355b;
  --blue-n-color: #015ba5;
  --blue-x-color: #485582;
  --blue-0a-color: #0a0e1b;
  --blue-ad-color: #3546ad;
  --blue-1b-color: #090d1b;
  --blue-a3-color: #5667a3;
  --blue-ff-color: #147aff;
  --blue-43-color: #202843;
  --blue-63-color: #2c3563;
  --blue-73-color: #2e3d73;
  --blue-84-color: #394984;
  --blue-90-color: #465690;
  --blue-92-color: #455492;
  --blue-1d-color: #1d2541;
  --blue-3b-color: #3b456b;
  --blue-ea-color: #8b9eea;
  --blue-c6-color: #909cc6;
  --blue-5e-color: #242f5e;
  --blue-7d-color: #32427d;
  --blue-8a-color: #014d8a;
  --blue-f0-color: #4b62f0;
  --blue-bc-color: #4455bc;
  --blue-7e-color: #4b567e;
  --blue-7b-color: #545d7b;
  --blue-eb-color: #6175eb;
  --blue-9b-color: #32449b;
  --blue-e4-color: #5c70e4;
  --blue-9c-color: #2d3d9c;
  --blue-18-color: #1890ff;
  --blue-14-color: #2e3b6a;
  --blue-29-color: #292929;
  --blue-4d-color: #28304d;
  --blue-59-color: #252e59;
  --blue-35-color: #1e2335;
  --blue-4a-color: #1e2a4a;
  --yellow-fa-color: #faad14;
  --yellow-ff-color: #ff9a00;
  --yellow-f0-color: #fa6400;
  --red-a-color: #ee495e;
  --red-1c-color: #96121c;
  --green-color: #0f0;
  --green-7a-color: #7aff78;

  /* layout color */
  --bg-color: var(--blue-a-color);
  --section-color: var(--blue-k-color);
  --content-color: var(--blue-8a-color);

  /* button color */
  --btn-bg-highlight-color: var(--blue-f0-color);
  --btn-bg-color: var(--blue-ad-color);
  --btn-bg-color-active: var(--blue-bc-color);
  --btn-border-color-disabled: var(--blue-92-color);
  --btn-color-disabled: var(--blue-92-color);
  --icon-bg-color-active: var(--blue-43-color);

  /* work list color */
  --work-list-color: var(--blue-73-color);
  --work-list-bg-color: var(--blue-k-color);
  --work-list-color-hover: var(--blue-84-color);
  --work-list-color-active: var(--blue-a3-color);

  /* header bg color */
  --blue-headerbg-color: var(--blue-1b-color);

  /* select bg color */
  --select-item-color: var(--blue-4d-color);
  --select-item-active-color: var(--blue-bc-color);
}

html,
body {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.85);
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  div {
    user-select: none;
  }
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

ul li {
  list-style: none;
}

img {
  user-select: none;
}
a,
input,
button {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(0, 0, 0, 0);
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
