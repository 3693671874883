.cursor-angle {
  cursor: url('~@sk/icons/cur/angle.cur'), auto;
}

.cursor-arrow {
  cursor: url('~@sk/icons/cur/arrow.cur'), auto;
}

.cursor-circular {
  cursor: url('~@sk/icons/cur/circular.cur'), auto;
}

.cursor-length {
  cursor: url('~@sk/icons/cur/length.cur'), auto;
}

.cursor-pan {
  cursor: url('~@sk/icons/cur/move.cur'), auto;
}

.cursor-position {
  cursor: url('~@sk/icons/cur/position.cur'), auto;
}

.cursor-rectangle {
  cursor: url('~@sk/icons/cur/rectangle.cur'), auto;
}

.cursor-rotate {
  cursor: url('~@sk/icons/cur/rotate.cur'), auto;
}

.cursor-section {
  cursor: url('~@sk/icons/cur/section.cur'), auto;
}

.cursor-text {
  cursor: url('~@sk/icons/cur/text.cur'), auto;
}

.cursor-zoom {
  cursor: url('~@sk/icons/cur/zoom.cur'), auto;
}

.cursor-drag {
  cursor: url('~@sk/icons/cur/move.cur'), auto;
}
